import React from "react";
import RegistrationForm from "../Components/registraion/RegistrationForm";
import '../Components/registraion/Registration.css';
import logo from "../images/libag-logo-small.png";
import loginImg from '../images/login-left-image.jpg';

function Registration () {
   
    return(
        <>
            <div className="wrap_login">
                <div className="login_wrapper">
                    <div className="login_left signup_left">                    
                        <img src={loginImg} />            
                    </div>
                    <div className="login_rgt sign_up_rgt">
                        <div className="login_logo">
                            <img className="logo" src={logo} alt="logo" />
                        </div>                   
                        <RegistrationForm />      
                    </div>
                </div>
            </div>
            
        </>
    )
}

export default Registration