import { useEffect, useState } from 'react';
import Header from "./Components/header/Header";
// import About from './Pages/About';
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./Components/footer/Footer";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import { Route, Routes } from "react-router-dom";
import TermsConditions from "./Pages/TermsConditions";
import Registration from "./Pages/Registration";
import Login from "./Pages/Login";
import Forgot from "./Pages/Forgot";
import "./common.css";

function App(props) {
  const [state, setState] = useState("login");

  const handleChange = (state) => {
    setState(state);
  };

  return (
    <div className="App">
      <Header
        handleChange={handleChange} state={state}
      />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="contact" element={<Contact />} />
        <Route path="terms" element={<TermsConditions />} />
        <Route path="registration" element={<Registration />} />
        <Route path="login" element={<Login />} />
        <Route path="forgot" element={<Forgot />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
