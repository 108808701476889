import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from 'universal-cookie';
import { authToken } from "./../../constant";



function LoginForm() {
  const cookies = new Cookies();

  const [formFields, setFormFields] = useState({
    email: "",
    password: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [checked, setChecked] = useState(false)

  useEffect(() => {

    if (authToken()) {
      navigate('/')
    }
    const detail = cookies.get('active_user')
    if (detail) {
      setFormFields((prev) => ({
        ...prev,
        email: detail.email,
        password: detail.password,
      }));
      setChecked(true)
    }
  }, [])

  const navigate = useNavigate();
  function handleValidation() {
    const { email, password } = formFields;
    let formErrors = {};
    let formIsValid = true;

    if (!email) {
      formIsValid = false;
      formErrors["emailErr"] = "User Name is required.";
    }
    if (!password) {
      formIsValid = false;
      formErrors["passwordErr"] = "Password is required.";
    }
    setFormErrors(formErrors);
    return formIsValid;
  }
  function handleChange(evt) {
    setFormFields((prev) => ({
      ...prev,
      [evt.target.name]: evt.target.value,
    }));
  }
  function handleLogin() {
    let signupField = formFields;
    console.warn(signupField);

    if (handleValidation()) {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/accounts/login/`, signupField)
        .then((res) => {
          if (checked) {
            cookies.set('active_user', formFields);
          } else {
            cookies.remove("active_user")
          }
          localStorage.setItem("user-info", JSON.stringify(res.data));
          localStorage.setItem("signedUp", JSON.stringify(false));
          navigate('/')
          window.location.reload()
        })
        .catch((err) => alert(err.response.data.message));
    }
  }
  const { emailErr, passwordErr } = formErrors;
  const handleCheckbox = () => {
    setChecked(!checked)
  }
  console.log(cookies.get('active_user'));
  return (
    <>
      <div className="form_field">
        <label>Username</label>
        <input
          type="text"
          value={formFields.email}
          name="email"
          onChange={handleChange}
          className="cstm_input  form-control"
        />
        {emailErr && <div className="password_error">{emailErr}</div>}
      </div>
      <div className="form_field">
        <label>Password</label>
        <input
          type="password"
          name="password"
          value={formFields.password}
          onChange={handleChange}
          className="cstm_input  form-control"
        />
        {passwordErr && <div className="password_error">{passwordErr}</div>}
      </div>

      <div className="forget_row">
        <div className="cstm_checkbox">
          <input type="checkbox" id="test1" checked={checked}
            onChange={() => handleCheckbox()} />
          <label for="test1">Stay signed in</label>
        </div>
        <div style={{ display: 'flex', flexDirection: "column" }}>
          <a className="forgot_link" href="/registration">
            SignUp
        </a>
          <p style={{ cursor: "pointer" }} onClick={() => navigate('/forgot')} className="forgot_link">Forgot Password</p>
        </div>
      </div>

      <div className="text-left" style={{ paddingTop: "0px", marginTop: "opx" }}>
        <button
          type="button"
          onClick={handleLogin}
          className="search_btn  btn_css aqua_btn"
        >
          Login
        </button>
      </div>
    </>
  );
}

export default LoginForm;
