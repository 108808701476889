import React from "react";

function BriefHistory() {
   return (
      <section className="contest about_contest">
         <div className="container">
            <div className="contest_wrap">
               <div className="contest_head text-center">
                  <h3 className="pri_head "><span>Our </span> Mission</h3>
                  <p>Our mission at lib.ag is to provide high-quality seeds and grains image data, to help improve quality, food safety, and security for global food markets.</p>
               </div>
            </div>
         </div>
      </section>
   )
}

export default BriefHistory