import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const RegistrationForm = () => {
  const [formState, setInputFormState] = useState({
    first_name: "",
    last_name: "",
    email: "",
    user_type: "",
    password: "",
    confirm_password: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();

  //form validation
  function handleValidation() {
    const {
      first_name,
      last_name,
      email,
      user_type,
      password,
      confirm_password,
    } = formState;
    let formErrors = {};
    let formIsValid = true;

    if (!first_name) {
      formIsValid = false;
      formErrors["firstnameErr"] = "First Name is required.";
    }
    if (!last_name) {
      formIsValid = false;
      formErrors["lastnameErr"] = "Last Name is required.";
    }
    if (!email) {
      formIsValid = false;
      formErrors["emailIdErr"] = "Email id is required.";
    } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      formIsValid = false;
      formErrors["emailIdErr"] = "Invalid email id.";
    }
    if (!user_type) {
      formIsValid = false;
      formErrors["user_typeErr"] = "User Type is required.";
    }
    if (!password) {
      formIsValid = false;
      formErrors["passwordErr"] = "Password is required.";
    } else {
      const uppercaseRegExp = /(?=.*?[A-Z])/;
      const lowercaseRegExp = /(?=.*?[a-z])/;
      const digitsRegExp = /(?=.*?[0-9])/;
      const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
      const minLengthRegExp = /.{8,}/;
      const passwordLength = password.length;
      const uppercasePassword = uppercaseRegExp.test(password);
      const lowercasePassword = lowercaseRegExp.test(password);
      const digitsPassword = digitsRegExp.test(password);
      const specialCharPassword = specialCharRegExp.test(password);
      const minLengthPassword = minLengthRegExp.test(password);
      if (passwordLength === 0) {
        formIsValid = false;
        formErrors["passwordErr"] = "Password is empty";
      } else if (!uppercasePassword) {
        formIsValid = false;
        formErrors["passwordErr"] = "At least one Uppercase";
      } else if (!lowercasePassword) {
        formIsValid = false;
        formErrors["passwordErr"] = "At least one Lowercase";
      } else if (!digitsPassword) {
        formIsValid = false;
        formErrors["passwordErr"] = "At least one digit";
      } else if (!specialCharPassword) {
        formIsValid = false;
        formErrors["passwordErr"] = "At least one Special Characters";
      } else if (!minLengthPassword) {
        formIsValid = false;
        formErrors["passwordErr"] = "At least minumum 8 characters";
      } else {
        formErrors["passwordErr"] = "";
        //formIsValid = true;
      }
    }
    if (!confirm_password) {
      formIsValid = false;
      formErrors["confirm_passwordErr"] = "Password is required.";
    }

    if (password !== confirm_password) {
      formIsValid = false;

      formErrors["password_match"] = "Password doesn't match.";
    }
    setFormErrors(formErrors);
    return formIsValid;
  }
  //handling signup
  const handleSignup = async () => {
    let signupField = formState;
    console.warn(signupField);
    if (handleValidation()) {
      let result = await fetch(
        `${process.env.REACT_APP_BASE_URL}/accounts/signup/`,
        {
          method: "POST",
          body: JSON.stringify(signupField),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      result = await result.json();
      localStorage.setItem("user-info", JSON.stringify(result));
      localStorage.setItem("signedUp", JSON.stringify(true));
      navigate("/");
      window.location.reload()

    } else {
      console.warn("error in form");
    }
  };

  function handleChange(evt) {
    setInputFormState((prev) => ({
      ...prev,
      [evt.target.name]: evt.target.value,
    }));
  }
  const {
    firstnameErr,
    lastnameErr,
    emailIdErr,
    user_typeErr,
    passwordErr,
    confirm_passwordErr,
    password_match
  } = formErrors;

  return (
    <>
      <div className="sign_up_row">
        <div className="form_field">
          <label>First name</label>
          <input
            type="text"
            value={formState.first_name}
            name="first_name"
            onChange={handleChange}
            className="cstm_input  form-control"
          />
          {firstnameErr && <div className="password_error">{firstnameErr}</div>}
        </div>
        <div className="form_field">
          <label>Last name</label>
          <input
            type="text"
            value={formState.last_name}
            name="last_name"
            onChange={handleChange}
            className="cstm_input  form-control"
          />
          {lastnameErr && <div className="password_error">{lastnameErr}</div>}
        </div>
        <div className="form_field">
          <label>Email Address</label>
          <input
            type="email"
            value={formState.email}
            name="email"
            onChange={handleChange}
            className="cstm_input  form-control"
          />
          {emailIdErr && <div className="password_error">{emailIdErr}</div>}
        </div>
        <div className="form_field">
          <label> User Type </label>
          <select
            value={formState.user_type}
            name="user_type"
            onChange={handleChange}
            className="cstm_input form-control"
          >
            <option value="Select User Type">Select User Type</option>
            <option value="PRIVATE_PERSON">Private person</option>
            <option value="ACADEMIA">Academia</option>
            <option value="NON_PROFIT">Non profit</option>
            <option value="BUSINESS">Business Sector</option>
            <option value="OTHERS ">Others</option>
          </select>
          {user_typeErr && <div className="password_error">{user_typeErr}</div>}
        </div>
        <div className="form_field">
          <label>Password</label>
          <input
            type="password"
            name="password"
            value={formState.password}
            onChange={handleChange}
            className="cstm_input  form-control"
          />
          <p style={{ fontSize: "9px" }}>must contain one-uppercase(A-Z), lowercase(a-z), mubbers & one-spacial-character("@","#")</p>
          {passwordErr && <div className="password_error">{passwordErr}</div>}
        </div>
        <div className="form_field full_wdth">
          <label>Confirm password</label>
          <input
            type="password"
            name="confirm_password"
            value={formState.confirm_password}
            onChange={handleChange}
            className="cstm_input  form-control"
          />
          {confirm_passwordErr && (
            <div className="password_error">{confirm_passwordErr}</div>
          )}
          {password_match && (<div className="password_error">{password_match}</div>)}
        </div>
        <div className="forget_row_signup">
          <a className="forgot_link" href="/login">
            SignIn
          </a>
        </div>
      </div>
      <div className="text-left" style={{ paddingButtom: "10px" }}>
        <button
          type="button"
          onClick={handleSignup}
          className="search_btn  btn_css aqua_btn"
        >
          Sign Up
        </button>
      </div>
    </>
  );
};

export default RegistrationForm;
