import React from "react";
import { useNavigate } from "react-router-dom";
import logout from "./../../images/logout.svg";

function Logout() {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
    window.location.reload();
  };
  return (
    <div className="dropdown_popup">
      <div className="logout_cont" onClick={() => handleLogout()}>
        <img className="logout" src={logout} />
        Logout
      </div>
    </div>
  );
}
export default Logout;
