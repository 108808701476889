import React from "react";
import '../Components/about/About.css';
import InnerBanner from "../Components/innerbanner/InnerBanner";
import BriefHistory from "../Components/about/BreifHistory";
import WhoWeAre from "../Components/about/WhoWeAre";



function About() {
    const contactData = "About us"
    return (
        <>
            <InnerBanner contactData={contactData} />
            <BriefHistory />
            <WhoWeAre />
        </>
    )
}

export default About