import React from "react";

function InnerBanner(props){
    console.log("propspropsprops",props.contactData)
    return(
        <>
            <section className="about_banner">
                <div className="bnnr_content1">
                <h1>{props?.contactData ? props?.contactData : null}</h1>
                </div>
            </section>
        </>
    )
}

export default InnerBanner