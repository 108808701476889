import React from 'react';
import homebanner from '../../images/home_banner.png';
import './Banner.css';
import Filter from '../filter/Filter';

function HomeBanner(props){
    return(
        <>
        <section className="home_banner">
            <img className="logo" src={homebanner} alt="logo" />
            <div className="banner_content">
               <h1>lib.ag provides high-quality seeds and grains image data, to help improve quality, food safety, and security for global food markets.</h1>
               <Filter {...props}/>
            </div>
         </section>
        </>
    )    
}

export default HomeBanner