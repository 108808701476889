import React, { useEffect, useState } from "react";
import HomeBanner from "../Components/banner/Banner";
import ProductListing from "../Components/productlisting/ProductListing";
import Group from "../images/Group.png";
import Cookies from 'universal-cookie';


function Home() {
  const cookies = new Cookies();

  const [data, setData] = useState([]);
  const [searchName, setSearchName] = useState();
  const [searchLength, setSearchLength] = useState('')
  const [reload, setReload] = useState(false);
  const [tagName, setTagName] = useState("");
  const [filData, setfilData] = useState("");
  useEffect(() => {
    callAPI();
    setReload(false)
  }, [reload]);

  const callAPI = async (searchValue = "") => {
    let result = await fetch(
      `${process.env.REACT_APP_BASE_URL}/seedcropdata/seeds/${searchValue}`
    );
    result = result.json();
    const p = Promise.resolve(result);
    p.then((value) => {
      setData(value);
      setfilData(value);
        setSearchLength(searchValue ? value.length : 0);
    });
  };

  const handleSearch = (name, tags) => {
    setTagName(tags);
    setSearchName(name, tags);
    const search = `?search=${tags && name ? tags + "," + name : tags || name
      }`;
    if (name || tags) callAPI(search);
  };

  const handleClear = () => {
    setTagName("")
    callAPI();
    setSearchLength(0);
    setSearchName("");
  }
  const handleFilterData = (data) => {
    setfilData(data);
  }
  useEffect(()=>{
    if(filData?.length&&data.length!==filData?.length){
      setSearchLength(filData?.length)
    }
  },[filData])
  const user = cookies.get('active_user')?.email;
  return (
    <>
      <HomeBanner onSearch={(filterName, filterTags) =>
        handleSearch(filterName, filterTags)}
        onDelete={handleClear}
        data={data}
        filterData={(data) => { handleFilterData(data) }}
        onClear={tagName}
      />
      <section className="csmt_accordian ptop90">
        <div className="container cstm_container">
          {/* {console.log("data",data)} */}
          <ProductListing
            data={filData}
            searchName={searchName}
            setReload={setReload}
            searchLength={searchLength}
            user={user}
          />
        </div>
      </section>
    </>
  );
}

export default Home;