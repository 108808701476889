import React from "react";
import ForgotForm from "../Components/forgot/ForgotForm";
import '../Components/registraion/Registration.css';
import logo from "../images/libag-logo-small.png";
import loginImg from '../images/login-left-image.jpg';

function Forgot() {

    return (
        <>
            <div className="wrap_login">
                <div className="login_wrapper">
                    <div className="login_left">
                        <img src={loginImg} />
                    </div>
                    <div className="login_rgt sign_up_rgt">
                        <div className="login_logo">
                            <img className="logo" src={logo} alt="logo" />
                        </div>
                        <ForgotForm />
                    </div>
                </div>
            </div>

        </>
    )
}

export default Forgot