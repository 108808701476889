import { useEffect } from "react";
import { useState } from "react";
import { Link } from 'react-router-dom';

function Filter(props) {
  let onClear = props?.onClear;
  let data = props?.data;
  let filterData = props?.filterData;

  const [filterName, setFilterName] = useState("");
  const [filterTags, setfilterTags] = useState([]);
  const [input, setInput] = useState('');
  const [tags, setTags] = useState([]);
  const [isKeyReleased, setIsKeyReleased] = useState(false);

  const filterHandle = (e) => {
    const trimmedInput = input.trim();
    let searchValue = [...tags, input];
    if (searchValue.length > 0) {
      let arr = [];
      let avlList = [...data];
      console.log('---------------', avlList )
      for (let i = 0; i < searchValue?.length; i++) {
        let val = avlList.filter(el => {
          // return (el?.tags)?.toLowerCase() === (searchValue[i]).toLowerCase()
          return el?.tags.includes((searchValue[i]).toLowerCase())
        });
        if (val) {
          arr.push(...val);
        }
      }
      console.log({arr})
      if (arr?.length > 0) {
        filterData(arr);
      }

      //props.onSearch(filterName, tags);
    } 
    console.log("che k",filterTags ,searchValue, filterName)
    if (filterName.length > 0 || searchValue?.filter(Boolean)?.length > 0) {
      props.onSearch(filterName, filterTags);
    }else{
      props.onDelete()
    }
  };

  useEffect(() => {
    if (onClear === "") {
      setfilterTags("");
    }
  }, [onClear]);

  const onChange = (e) => {
    const { value } = e.target;
    setInput(value);
  };

  const onKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = input.trim();

    if (key === ',' && trimmedInput.length && !tags.includes(trimmedInput)) {
      e.preventDefault();
      setTags(prevState => [...prevState, trimmedInput]);
      setInput('');
    }
    if (key === 'Enter' && trimmedInput.length && !tags.includes(trimmedInput)) {
      e.preventDefault();
      setTags(prevState => [...prevState, trimmedInput]);
      setInput('');
    }

    if (key === "Backspace" && !input.length && tags.length) {
      e.preventDefault();
      const tagsCopy = [...tags];
      const poppedTag = tagsCopy.pop();

      setTags(tagsCopy);
      setInput(poppedTag);
    }
    setIsKeyReleased(false);
  };

  const deleteTag = (index) => {
    setTags(prevState => prevState.filter((tag, i) => i !== index))
  }

  return (
    <>
      <div className="bannar_serach">
        <h4>Search by Name</h4>
        <div className="search_box">
          <div className="search_wrap">
            <input
              value={filterName}
              onChange={(e) => setFilterName(e.target.value)}
              className="cstm_input search_input form-control"
              type="text"
              placeholder="Example: rice, wheat"
            />

            <div className="select1">
              {
                tags.map((tag, index) => (
                  <div className="tag" key={index}>
                    {tag}
                    <button onClick={() => deleteTag(index)}>x</button>
                  </div>
                ))
              }
              <input
                value={input}
                // transparent
                onChange={onChange}
                className="cstm_input search_input form-control"
                type="text"
                placeholder="Search by tags"
                onKeyDown={onKeyDown}
              // onEnter={onEnter}
              />
            </div>
            <Link
              className="search_btn  btn_css aqua_btn"
              to="#"
              onClick={() => filterHandle()}
            >
              Search
            </Link>
          </div>
        </div>
        <a href="/contact" className="contactus_link">
          Contact us for a specific data
        </a>
      </div>
    </>
  );
}

export default Filter;