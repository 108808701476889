import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { authToken } from "../../constant";



function ForgotForm() {
    const [formFields, setFormFields] = useState({
        email: "",
        password: "",
        confirm_password: ""
    });
    const [formErrors, setFormErrors] = useState({});

    const navigate = useNavigate();
    function handleValidation() {
        const { email, password, confirm_password } = formFields;
        let formErrors = {};
        let formIsValid = true;

        if (!email) {
            formIsValid = false;
            formErrors["emailErr"] = "Email is required.";
        }
        if (!password) {
            formIsValid = false;
            formErrors["passwordErr"] = "Password is required.";
        }
        if (password != confirm_password) {
            formIsValid = false;
            formErrors["confirmPasswordErr"] = "Password and confirm password must be same.";
        }
        setFormErrors(formErrors);
        return formIsValid;
    }
    function handleChange(evt) {
        setFormFields((prev) => ({
            ...prev,
            [evt.target.name]: evt.target.value,
        }));
    }
    function handleLogin() {
        let forgotField = formFields;
        console.warn(forgotField);

        if (handleValidation()) {
            axios
                .post(`${process.env.REACT_APP_BASE_URL}/accounts/forgotpassword/`, forgotField)
                .then((res) => {
                    alert(res.data.msg)
                    navigate('/login')
                })
                .catch((err) => alert(err.response.data.msg));
        }
    }
    const { emailErr, passwordErr, confirmPasswordErr } = formErrors;
    return (
        <>
            <div className="form_field">
                <label>Email</label>
                <input
                    type="text"
                    value={formFields.email}
                    name="email"
                    onChange={handleChange}
                    className="cstm_input  form-control"
                />
                {emailErr && <div className="password_error">{emailErr}</div>}
            </div>
            <div className="form_field">
                <label>New Password</label>
                <input
                    type="password"
                    name="password"
                    value={formFields.password}
                    onChange={handleChange}
                    className="cstm_input  form-control"
                />
                {passwordErr && <div className="password_error">{passwordErr}</div>}
            </div>
            <div className="form_field">
                <label>Confirm New Password</label>
                <input
                    type="password"
                    name="confirm_password"
                    value={formFields.confirm_password}
                    onChange={handleChange}
                    className="cstm_input  form-control"
                />
                {confirmPasswordErr && <div className="password_error">{confirmPasswordErr}</div>}
            </div>
            <div className="text-left" style={{ paddingTop: "0px", marginTop: "opx" }}>
                <button
                    type="button"
                    onClick={handleLogin}
                    className="search_btn  btn_css aqua_btn"
                >
                    Submit
        </button>
            </div>
        </>
    );
}

export default ForgotForm;
