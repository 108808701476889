import logo from "../../images/libag-logo-small.png";
import userImg from "../../images/user.png";
import Image from "react-bootstrap/Image";
import "./Header.css";
import { Link } from "react-router-dom";
import { authToken, getFirstname, isSignedUp } from "./../../constant";
import DropDown from "./../../images/dropdown.png";
import { useState } from "react";
import Logout from "../registraion/Logout";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

function Header({ handleChange = () => {}, state }) {
  const [open, setOpen] = useState(false);
  const [showNav, setShowNav] = useState(false)

  const firstName = JSON.parse(localStorage.getItem("user-info"))?.first_name;
  const handleShownav = () =>{
    setShowNav(!showNav)
  }

  return (
    <>
      <header className="header_main">
        <div className="container cstm_container">
          <div className="header_wrap">
            <Link to="/">
              <img style={{ width: "156px"}}className="logo" src={logo} alt="logo" />
            </Link>
            <button type="button" className="mob_menu" wfd-id="300" onClick={()=>handleShownav()}>
            <FontAwesomeIcon icon={faBars}/>
            </button>
            <ul className={!showNav?  "header_list nav_menu" : "header_list nav_menu show"} >
              <li >
                <Link to="/" className={"active"} onClick={()=>setShowNav(false)}>
                  Home{" "}
                </Link>
              </li>
              <li >
                <Link to="/about" onClick={()=>setShowNav(false)}>About us </Link>
              </li>
              <li >
                <Link to="/contact" onClick={()=>setShowNav(false)}>Contact Us</Link>
              </li>
              <li >
                <Link to="/terms" onClick={()=>setShowNav(false)}>Terms & Agreements</Link>
              </li>
              {authToken() === undefined ? (
                <ul className="header_list nav_menu">
                  <li>
                    <Link
                      to="/login"
                      className={`btn_css ${
                        state === "login" ? "aqua_btn" : "white_btn"
                      }`}
                      onClick={() => {
                        handleChange("login")
                        setShowNav(false)
                      }}
                    >
                      LOGIN
                    </Link>
                  </li>
                  {!isSignedUp() && (
                    <li>
                      <Link
                        to="/registration"
                        className={`btn_css ${
                          state === "login" ? "white_btn" : "aqua_btn"
                        }`}
                        onClick={() => {
                          handleChange("sinup")
                          setShowNav(false)
                        }}
                      >
                        Signup
                      </Link>
                    </li>
                  )}
                </ul>
              ) : (
                <div className="header_list nav_menu dropdown_cont">
                  <Image className="avtar" src={userImg} rounded />
                  <p className="welcome">{getFirstname()}</p>
                  <img
                    className="dropdown_img"
                    src={DropDown}
                    onClick={() => setOpen(!open)}
                    alt="drp_dwn"
                  />
                  {open && <Logout />}
                </div>
              )}
            </ul>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
