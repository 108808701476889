import React from "react";
import introImg from '../../images/contact_banner.jpg';

function WhoWeAre() {
   return (
      <section className="intro">
         <div className="container">
            <div className="intro_row">
               <div className="intro_lft">
                  <img src={introImg} />
               </div>
               <div className="intro_rgt">
                  {/* <h2 className="pri_head ">
                        Who <span>We Are </span> 
                     </h2> */}
                  <p>Today one of the biggest challanges of seeds and grains analysis is to access a large number of quality images, to build prediction and classification models</p>
                  <p style={{ marginTop: '30px' }}>The lib.ag community is about sharing data and helping everyone with a mission to solve the food system critical issues that we are facing these days</p>
               </div>
            </div>
         </div>
      </section>
   )
}

export default WhoWeAre