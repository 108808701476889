import React, { useState } from "react";
import { authToken } from "../../constant";
import userImg from "../../images/user_icn.svg";

const Reply = (props) => {
  const [open, setOpen] = useState(false)
  function handleSubmit() {
    if (authToken()) {
      props.postComment(props.id, props.index);
      props.hideModal();
    } else alert("Please login to reply");
  }
  return (
    <div className="reply_textarea_col">
      {props.length !== 0 && (
        <p className="reply_length" onClick={()=> setOpen(!open)}>({props.length}) View previous replies</p>
      )}
{open &&(
  props.data.map((val, index) => (
    <div className="commets" key={index}>
      <div className="user_icn">
        <img
          className="logo"
          src={userImg}
          alt="logo"
        />
      </div>
      <div className="commets_details">
        <div className="comment_name">
          <h5>{val.user}</h5>

          <div>
            <i
              className="fa fa-star"
              aria-hidden="true"
            ></i>
            <i
              className="fa fa-star"
              aria-hidden="true"
            ></i>
            <i
              className="fa fa-star"
              aria-hidden="true"
            ></i>
            <i
              className="fa fa-star"
              aria-hidden="true"
            ></i>
            <i
              className="fa fa-star-half-o"
              aria-hidden="true"
            ></i>
          </div>
        </div>
        <p>{val.comment}</p>
      </div>
    </div>
  )))}
 {props.showreplyBox && <>
 
      <textarea
        onChange={(evt) => props.handleChange(evt)}
        name="replyMessage"
        placeholder="Mentioned your feedback here"
      ></textarea>
      <button
        className="feedback_submit"
        onClick={() => handleSubmit()}
        disabled={!props.replyMessage}
      >
        Submit
      </button>
      <button
        className="feedback_submit white_btn"
        onClick={() => props.hideModal()}
      >
        Close
      </button>
 </>}
    </div>
  );
};
export default Reply;
