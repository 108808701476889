import axios from "axios";
import React, { useState } from "react";
import "../Components/contact/Contact.css";
import InnerBanner from "../Components/innerbanner/InnerBanner";

function Contact() {
  const [formFields, setFormFields] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    subject: "",
  });
  const [formErrors, setFormErrors] = useState({});

  function handleValidation() {
    const { name, email, message } = formFields;
    let formErrors = {};
    let formIsValid = true;

    if (!name) {
      formIsValid = false;
      formErrors["nameErr"] = "Name is required.";
    }
    if (!message) {
      formIsValid = false;
      formErrors["messageErr"] = "Message is required.";
    }
    if (!email) {
      formIsValid = false;
      formErrors["emailIdErr"] = "Email id is required.";
    } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      formIsValid = false;
      formErrors["emailIdErr"] = "Invalid email id.";
    }

    setFormErrors(formErrors);
    return formIsValid;
  }
  function handleChange(evt) {
    setFormFields((prev) => ({
      ...prev,
      [evt.target.name]: evt.target.value,
    }));
  }
  function handleSubmit() {
    if (handleValidation()) {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/accounts/contactus/`, formFields)
        .then((res) => {
          alert("Your request has been submitted");
          setFormFields({
            name: "",
            email: "",
            phone: "",
            message: "",
            subject: "",
          });
        })
        .catch((err) => alert(err.response.data.email));

    }

  }
  const { nameErr, messageErr, emailIdErr } = formErrors
  const contactData = "Contact us"
  return (
    <>
      <InnerBanner contactData={contactData} />
      <section className="field_section">
        <div className="container">
          <h3 className="sub_hdng">Feel free to contact us</h3>
          <div className="form_row">
            <div className="feild_inputs contact_input">
              <div className="input_row">
                <label>Name</label>
                <input
                  value={formFields.name}
                  type="text"
                  name="name"
                  placeholder="Name"
                  className="cstm_input  form-control"
                  onChange={handleChange}
                />
                {nameErr && <div className="errors">{nameErr}</div>}
              </div>
              <div className="input_row">
                <label>Email</label>
                <input
                  value={formFields.email}
                  type="text"
                  name="email"
                  placeholder="Email"
                  className="cstm_input  form-control mrg_rgt0"
                  onChange={handleChange}
                />
                {emailIdErr && <div className="errors">{emailIdErr}</div>}
              </div>

            </div>
            <div className="feild_inputs contact_input">
              <div className="input_row">
                <label>Subject</label>
                <input
                  type="text"
                  value={formFields.subject}
                  name="subject"
                  placeholder="Subject"
                  className="cstm_input  form-control"
                  onChange={handleChange}
                />
              </div>
              <div className="input_row">
                <label>Phone</label>
                <input
                  type="text"
                  name="phone"
                  placeholder="Phone"
                  className="cstm_input  form-control mrg_rgt0"
                  onChange={handleChange}
                  value={formFields.phone}
                />
              </div>
            </div>
            <div className="form_feild input_row">
              <label>Message</label>
              <textarea
                className="cstm_input  form-control"
                name="message"
                value={formFields.message}
                onChange={handleChange}
              >
                Descriptions
              </textarea>
              {messageErr && <div className="errors msgError">{messageErr}</div>}
            </div>
            <div className="form_btn txt_center">
              <a
                onClick={handleSubmit}
                className="search_btn  btn_css aqua_btn"
              >
                submit
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact;
