import React from "react";
import './Footer.css';
import flogo from "../../images/lib-ag-logo.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';

function Footer() {
    return(
        <>
            <footer >
                <div className="cstm_container container">
                <div className="footer_wrap">
                    <div className="footer_lft">
                        <a href="#" className="fotter_logo"><img  className="logo" src={flogo} alt="logo" /></a>
                        {/* <ul className="footer_list">
                            <li><a href="#"><FontAwesomeIcon icon={faComment}></FontAwesomeIcon></a></li>
                            <li><a href="#"><FontAwesomeIcon icon={faTwitter}></FontAwesomeIcon></a></li>
                            <li><a href="#"><FontAwesomeIcon icon={faYoutube}></FontAwesomeIcon></a></li>
                        </ul> */}
                    </div>
                    <div className="footer_rgt">
                        <ul>
                            <li><a href="#">Home</a></li>
                            <li><a href="#">About us</a></li>
                            <li><a href="#">Contact Us</a></li>
                            
                        </ul>
                        <p>ALL RIGHTS RESERVED. © lib.ag 2022</p>
                    </div>
                </div>
                </div>
            </footer>
        </>
    )
}


export default Footer