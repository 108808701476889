import { Accordion } from "react-bootstrap";
import { useState } from "react";
import "./Productlisting.css";
import TotalRating from "../Rating/TotalRating";
import ProductDetail from "./ProductDetail";
import userImg from "../../images/user_icn.svg";
import Reply from "./Reply";
import { authToken, isSignedUp, getUserId } from "../../constant";
function ProductListing({ data, searchLength, setReload }) {
  const [state, setState] = useState({
    fullName: "",
    message: "",
    rating: "",
    replyMessage: "",
    commentMessage: "",
  });
  const [replyModal, setReplyModal] = useState(0);
  const [showComment, setShowComment] = useState(null);
  const [showComment1, setShowComment1] = useState(null);
  const [showReviewField, setShowReviewField] = useState(true);
  const [currentSeedId, setCurrentSeedId] = useState();

  function getModal(value) {
    setReplyModal(value);
  }
  function hideModal() {
    setReplyModal(0);
  }

  function handleChange(evt) {
    setState((prev) => ({ ...prev, [evt.target.name]: evt.target.value }));
  }
  const postComment = (id, index) => {
    let commentFields = {
      reply_to_comment: id + "",
      comment: state.message || state.replyMessage || state.commentMessage,
      review: state.rating,
    };
    fetch(
      `${process.env.REACT_APP_BASE_URL}/seedcropdata/CommentSeedApi/?seed_id=${currentSeedId}`,
      {
        method: "POST",
        body: JSON.stringify(commentFields),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken()}`,
        },
      }
    )
      .then((val) => {
        if (val.status < 400) {
          setReload(true);
        }
      })
      .catch((err) => console.log(err));
  };

  function handleSubmit(e, index) {
    if (authToken()) {
      e.preventDefault();
      postComment("", index);
      setState((prev) => ({
        ...prev,
        fullName: "",
        message: "",
        rating: "",
        replyMessage: "",
        commentMessage: "",
      }));
    } else alert("Please login to continue");
  }

  const handleCommenttoggle = (index) => {
    setShowComment(showComment !== index ? index : null);
  };
  const handleHeadertoggle = (index, currendId) => {
    setShowReviewField(true);
    setShowComment1(showComment1 !== index ? index : null);
    setCurrentSeedId(currendId);
  };
  const isSet=data?.length? [...new Set(data?.map(item=>item?.id))]:[]
  const list=isSet?.map(item=>{
    return data?.filter(val=>val?.id===item)?.[0]
  })
  return (
    <>
      {searchLength ? (
        <h2>{searchLength} Products Found</h2>
      ) : (
        <h2>Products</h2>
      )}
      <Accordion>
        {list.length > 0 &&
          list?.map((item, index) => (
            <Accordion.Item eventKey={index + ""} key={index}>
              <Accordion.Header
                onClick={() => handleHeadertoggle(index, item.id)}
              >
                {item.name}
              </Accordion.Header>
              <Accordion.Body>
                <div className="acc_body">
                  <TotalRating
                    ratingReview={item?.comments}
                    ratings_avg={item?.ratings_avg}
                  />
                  <div className="acc_rgt">
                    <ProductDetail
                      image={item?.seed_files?.[0]?.images}
                      seed_type={item.seed_type}
                      country={item.country}
                      description={item.description}
                      tags={item.tags}
                      // pdf={item?.seed_files?.[0]?.files}
                      pdf={item?.file_data}
                      authToken={authToken}
                    />
                    <div className="reply_wrap">
                      <h4
                        style={{ cursor: "pointer" }}
                        onClick={() => handleCommenttoggle(index)}
                      >
                        User Comments({item?.comments?.length})
                        {/* <span style={{ float: "right" }}>
                        {item?.comments?.length} Comments
                      </span> */}
                      </h4>
                      {showComment1 === index &&
                        item.comments.map((res, id) => {
                          if (
                            res.user_id == getUserId() &&
                            res?.review > 0
                          ) {
                            if (showReviewField) {
                              setShowReviewField(false);
                            }
                          }
                        })}
                      {showComment === index && (
                        <>
                          {item.comments.map((res, id) => {
                            return (
                              <div className="commets">
                                <div className="user_icn">
                                  <img
                                    className="logo"
                                    src={userImg}
                                    alt="logo"
                                  />
                                </div>
                                <div className="commets_details">
                                  <div className="comment_name">
                                    <h5>{res.user}</h5>
                                    <div>
                                      <i
                                        className="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                      <i
                                        className="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                      <i
                                        className="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                      <i
                                        className="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                      <i
                                        className="fa fa-star-half-o"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                  </div>
                                  <p>{res.comment}</p>

                                  <a onClick={() => getModal(id + 1)}>Reply</a>
                                  {/* {replyModal === id + 1 && ( */}
                                  <Reply
                                    length={res?.replies?.length}
                                    handleChange={handleChange}
                                    postComment={postComment}
                                    index={index}
                                    id={res?.id}
                                    showreplyBox={replyModal === id + 1}
                                    hideModal={hideModal}
                                    replyMessage={state.replyMessage}
                                    data={res?.replies}
                                  />
                                  {/* )} */}
                                </div>
                              </div>
                            );
                          })}
                          <div>
                            <form>
                              <div className="form_feild input_row">
                                <label>Comment</label>
                                <textarea
                                  className="cstm_input  form-control"
                                  name="commentMessage"
                                  value={state.commentMessage}
                                  onChange={(e) => {
                                    e.preventDefault();
                                    handleChange(e);
                                  }}
                                  placeholder="Add Comment"
                                />
                              </div>
                              <div className="form_btn txt_center">
                                {console.log("itemId", currentSeedId)}
                                <button
                                  onClick={(e) =>
                                    handleSubmit(e, currentSeedId)
                                  }
                                  className="search_btn  btn_css aqua_btn"
                                  disabled={!state.commentMessage}
                                >
                                  submit
                                </button>
                              </div>
                            </form>
                          </div>
                        </>
                      )}
                    </div>
                    {(isSignedUp() || authToken()) && showReviewField && (
                      <div className="form_row">
                        <form>
                          <div className="feild_inputs contact_input">
                            <div className="input_row">
                              <label>Name</label>
                              <input
                                type="text"
                                name="fullName"
                                placeholder="Name"
                                className="cstm_input form-control"
                                onChange={handleChange}
                                value={state.fullName}
                              />
                            </div>
                            <div className="input_row">
                              <label>Rating</label>
                              <select
                                className="cstm_input form-control"
                                name="rating"
                                onChange={(e) => {
                                  e.preventDefault();
                                  handleChange(e);
                                }}
                                value={state.rating}
                              >
                                <option value="">Rating</option>
                                <option value="1">1 star</option>
                                <option value="2">2 star</option>
                                <option value="3">3 star</option>
                                <option value="4">4 star</option>
                                <option value="5">5 star</option>
                              </select>
                            </div>
                          </div>
                          <div className="form_feild input_row">
                            <label>FEEDBACK</label>
                            <textarea
                              className="cstm_input  form-control"
                              name="message"
                              value={state.message}
                              onChange={(e) => {
                                e.preventDefault();
                                handleChange(e);
                              }}
                              placeholder="Add Text"
                            />
                          </div>
                          <div className="form_btn txt_center">
                            {/* {console.log("itemId", currentSeedId)} */}
                            <button
                              onClick={(e) => handleSubmit(e, currentSeedId)}
                              className="search_btn  btn_css aqua_btn"
                              disabled={
                                !state.message ||
                                !state.fullName ||
                                !state.rating
                              }
                            >
                              submit
                            </button>
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          ))}
      </Accordion>
    </>
  );
}

export default ProductListing;
