import React from "react";
import {Accordion, Button, Card} from 'react-bootstrap';
import TermsData from "./termsConditionData";
import InnerBanner from "../Components/innerbanner/InnerBanner";



function TermsConditions () {
    const contactData = "Terms and Conditions"
    return(
        <>
        <InnerBanner contactData={contactData} />
        <section className="contest about_contest term_condn">
            <div className="container" style={{marginBottom: "30px"}}>
               <div className="contest_wrap">
                  <div className="contest_head text-center">
                  <p>Last updated: July 27, 2022</p>
                  <p>Please read these terms and conditions carefully before using Our Service.</p>
                  <TermsData />
                  </div>
                  
               </div>
            </div>
         </section>
         {/* <section className="csmt_accordian">
            <div className="container cstm_container">              
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>IA Wheat #1</Accordion.Header>
                        <Accordion.Body>
                            sdsdsd
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>IA Wheat #2</Accordion.Header>
                        <Accordion.Body>
                        test
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
         </section> */}
        </>
    )
}

export default TermsConditions