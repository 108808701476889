import React from "react";
import "./Rating.css";

function TotalRating({ ratingReview, ratings_avg }) {
  const reviewBook = ratingReview?.filter((item) => item.review);

  return (
    <div className="acc_left">
      {ratings_avg ? <h5>Rating : {ratings_avg.toFixed(1)} </h5> : null}
      {reviewBook?.length ?
        reviewBook?.map((item) => {
          return (
            <div className="cstm_progress" key={item["review"]}>
              <span>
                {item["review"]} {item["review"] > 1 ? "Stars" : "Star"}
              </span>
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: 100 / reviewBook?.length + "%" }}
                  aria-valuenow="100"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <span>{(100 / reviewBook?.length).toFixed(2)}%</span>
            </div>
          );
        }) : null}
    </div>
  );
}

export default TotalRating;
