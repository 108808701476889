import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { authToken } from "../../constant";

const ProductDetail = (props) => {
  return (
    <>
      <div className="acc_rgt_top">
        <Carousel autoPlay interval="5000" transitionTime="500">
          {props?.image?.length ? (
            props?.image?.map((value) => {
              return (
                <div>
                  {" "}
                  <img
                    key={value}
                    src={`${process.env.REACT_APP_BASE_URL}${value}`}
                    alt="grains"
                  />
                </div>
              );
            })
          ) : (
            <>
              <div>
                <img
                  src="https://cdn.pixabay.com/photo/2021/07/21/12/49/error-6482984_960_720.png"
                  alt="randomPic"
                />
              </div>
            </>
          )}
        </Carousel>
        <div className="product_details">
          {/* <h3>Product: {props.seed_type}</h3> */}
          {props.country && <h3>Contry: {props.country} </h3>}
          <p>{props.description}</p>
          <ul className="tag_tabs">
            {props.tags?.map((tags) => (
              <li className="tag_text" key={tags}>
                <a href="#">{tags}</a>
              </li>
            ))}
            {/* <li className="tag_text" >
              <a href="#">yellow</a>
            </li>
            <li className="tag_text" >
              <a href="#">green</a>
            </li> */}
          </ul>
          {props?.pdf?.length> 0 &&
            // props?.pdf
            //   ?.filter((x) => !!x)
            //   .map((zip) => {
            //     return (
                  <a
                    // key={zip}
                    className="search_btn  btn_css aqua_btn"
                    href={authToken() ? props?.pdf : '/login'}
                    // href={authToken() ? `${process.env.REACT_APP_BASE_URL}${props?.pdf}` : '/login'}
                    download={authToken() ? true : false}
                  >
                    Download
                  </a>
                // );
              // })
              }
        </div>
      </div>
    </>
  );
};

export default ProductDetail;
